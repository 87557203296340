@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap);

body {
  font-family: "Montserrat";
  min-height: -webkit-fill-available;
}

#fullpage-wrapper {
  margin-top: -65px;
}

.logo {
  width: 10%;
  z-index: 99999;
  position: absolute;
}

/* fond */
.fondPanier {
  background: url(/static/media/shop.07f781f6.png) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 30vh;
  margin-top: -9%;
}

.fond {
  /* background: url(../img/background/fleur2.jpg) no-repeat center; */
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}

.videoTag {
  width: 100vw;

  top: 0;
  padding: none;
  position: fixed; /* optional depending on what you want to do in your app */
}

.loc-img1 {
  background: url(/static/media/biodiversite.8762fe4c.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
  margin-left: -3%;
}

.loc-img2 {
  background: url(/static/media/entreprise2.c71dc638.jpg) no-repeat center;
  background-size: cover;
  height: 100vh;
  width: 51vw;
}

.loc-img3 {
  background: url(/static/media/particulier.5c6faf3a.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
  margin-left: -3%;
}
.loc-img4 {
  background: url(/static/media/particulier2.11d217ed.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
}
.img1 {
  background: url(/static/media/biodiversite2.3c812761.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
  margin-left: -3%;
}
.img2 {
  background: url(/static/media/famille.27f40651.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
}

.img3 {
  background: url(/static/media/rucheenhauteur.90b69a41.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
  margin-left: -3%;
}

.img4 {
  background: url(/static/media/rucheconnectee.d2d534b5.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
}
.fondEq {
  background: url(/static/media/fondEquipe.64371329.jpeg) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.fondLocation {
  background: url(/static/media/shop.9f44b85d.jpg) no-repeat center;
  background-size: cover;
  width: 100%;
  margin-top: 5%;
  height: 30vh;
}

.fondM {
  background: url(/static/media/feuille.8bd94b57.jpg) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  margin-top: -65px;
  z-index: 1;
}

.fondShop {
  background: url(/static/media/shop.9f44b85d.jpg) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 20vh;
  margin-top: -5%;
}

/* section */
.fp-section {
  background-color: transparent;
}

/* text-color */
.text-red {
  color: #82293b;
}
.text-red-link {
  color: #82293b;
  text-decoration: underline;
}

.text-red-link:hover {
  color: #82293b;
}

.text-blue {
  color: #1e362d;
}

.text-blue-link {
  color: #1e362d;
}

.text-blue-link:hover {
  color: #1e362d;
}

.text-light-blue {
  color: #2e6766;
}
.text-light-blue-link {
  color: #2e6766;
}
.text-light-blue-link:hover {
  color: #2e6766;
}

.text-white {
  color: #fff;
}

.lien {
  text-decoration: none !important;
  color: #000 !important;
}
/* texte-size */
.font-12px {
  font-size: 12px;
}
.font-14px {
  font-size: 14px;
}
.font-16px {
  font-size: 16px !important;
}
.font-18px {
  font-size: 18px;
}
.font-20px {
  font-size: 20px !important;
}
.font-26px {
  font-size: 26px;
}
.font-30px {
  font-size: 30px;
}
.font-35px {
  font-size: 35px;
}
.font-40px {
  font-size: 40px;
}
.font-50px {
  font-size: 50px;
}
.font-60px {
  font-size: 60px !important;
}
.font-70px {
  font-size: 70px;
}
.font-80px {
  font-size: 80px;
}
.font-90px {
  font-size: 90px;
}
.font-100px {
  font-size: 100px !important;
}
.font-120px {
  font-size: 120px;
}
.font-130px {
  font-size: 130px;
}

.font-italic {
  font-style: italic;
}
.font-bold {
  font-weight: bold;
}
.font-normal {
  font-style: normal;
}

/* margin */

.mt-10 {
  margin-top: 10%;
}

.mb-10 {
  margin-bottom: 10%;
}

.plr-5 {
  padding-right: 5%;
  padding-left: 5%;
}

/* btn */
.swiper-pagination-bullet-active {
  background-color: #2e6766;
}

.swiper-button-next,
.swiper-button-prev {
  color: #2e6766;
}

.btn-dark {
  background-color: #82293b;
  padding: 3%;
  color: white !important;
  border: none;
}

.btn-location {
  background-color: white;
  color: #82293b;
  padding: 5%;
  border-color: #82293b;
  width: 100%;
}

.btn-qt {
  background-color: #82293b;
  color: white !important;
  text-decoration: none !important;
  margin: 10px;
  padding: 10px;
}

.btn-qt::hover {
  background-color: #310d14 !important;
  text-decoration: none !important;
  color: white !important;
}

.btn-location:hover {
  background-color: #82293b;
  border-color: #82293b;
}

/* bouton ajouter au panier -------------------------- */

.cart-button {
  position: relative;
  padding: 10px;
  width: 300px;
  height: 60px;
  border: 0;
  background-color: #82293b;
  border-radius: 4rem;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  overflow: hidden;
}
.cart-button:hover {
  background-color: #44111b;
}
.cart-button:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.cart-button .fa-shopping-cart {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: -10%;
  font-size: 2em;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.cart-button .fa-box {
  position: absolute;
  z-index: 3;
  top: -20%;
  left: 52%;
  font-size: 1.2em;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.cart-button span {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  font-size: 1em;
  color: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.cart-button span.add-to-cart {
  opacity: 1;
}
.cart-button span.added {
  opacity: 0;
}

.cart-button.clicked .fa-shopping-cart {
  -webkit-animation: cart 1.5s ease-in-out forwards;
          animation: cart 1.5s ease-in-out forwards;
}
.cart-button.clicked .fa-box {
  -webkit-animation: box 1.5s ease-in-out forwards;
          animation: box 1.5s ease-in-out forwards;
}
.cart-button.clicked span.add-to-cart {
  -webkit-animation: txt1 1.5s ease-in-out forwards;
          animation: txt1 1.5s ease-in-out forwards;
}
.cart-button.clicked span.added {
  -webkit-animation: txt2 1.5s ease-in-out forwards;
          animation: txt2 1.5s ease-in-out forwards;
}
@-webkit-keyframes cart {
  0% {
    left: -10%;
  }
  40%,
  60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
@keyframes cart {
  0% {
    left: -10%;
  }
  40%,
  60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
@-webkit-keyframes box {
  0%,
  40% {
    top: -20%;
  }
  60% {
    top: 40%;
    left: 52%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}
@keyframes box {
  0%,
  40% {
    top: -20%;
  }
  60% {
    top: 40%;
    left: 52%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}
@-webkit-keyframes txt1 {
  0% {
    opacity: 1;
  }
  20%,
  100% {
    opacity: 0;
  }
}
@keyframes txt1 {
  0% {
    opacity: 1;
  }
  20%,
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes txt2 {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes txt2 {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* card */

.cardShop {
  border-radius: 50px;
  box-shadow: 0px 5px 5px 0px rgba(122, 122, 122, 0.4);
  background-color: #fff;
  padding: 10%;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms, -webkit-transform 250ms;
}

.cardShop:hover {
  box-shadow: 0px 10px 10px 0px rgba(122, 122, 122, 0.4);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer;
}

.cardShop h1,
.cardShop h2,
.cardShop h3 {
  color: #82293b;
  text-transform: uppercase;
  text-align: center;
}

.cardImg {
  width: 100%;
  height: 35%;
  margin: 5%;
  -webkit-align-items: center;
          align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.prix {
  text-align: center;
  color: #82293b;
  font-weight: bold;
}

.card {
  border-radius: 50px;
  box-shadow: 0px 5px 5px 0px rgba(122, 122, 122, 0.4);
  background-color: #fff;
  padding: 3%;
  margin-left: 4%;
}

/* form */

.cp-form {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 30px;
  padding-right: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-radius: 4rem;
  background-color: rgb(236, 236, 236);
  color: grey;
  width: 300px;
  height: 40px;
  border: solid 2px white;
}

.form-contact {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 10px;
  padding-right: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-radius: 4rem;
  width: 100%;
  margin: 1%;
  height: 50px;
  border: none;
  background-color: rgb(230, 230, 230);
}

.form-contact-message {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-radius: 1.5rem;
  width: 100%;
  margin: 1%;
  height: 250px;
  border: none;
  background-color: rgb(230, 230, 230);
}

/* carrousel cueilleur */

.carousel-cueilleur .swiper-container {
  height: 85vh;
  overflow-y: hidden;
}

.carousel-cueilleur .swiper-slide {
  text-align: center;
  background: transparent;
  width: 30vw;

  /* Center slide text vertically */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.carousel-cueilleur .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* img */

.hidden {
  visibility: hidden;
  height: 0px;
  margin: 0px;
}

.img-responsive {
  width: 100%;
}

.img-active {
  margin-bottom: 5%;
}

.img-produit:hover {
  cursor: pointer;
  opacity: 0.8;
}

.photo-equipe {
  height: 30vh;
  width: 70%;
}

.photo-equipe img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-chrono {
  width: 90%;
}

/* footer */

.footer {
  background-color: #383838;
  color: white;
  font-size: 1em;
  padding: 10px 10px 10px 10px;
  border-top: 5px solid #a3c6b1;
  background: #383838 url(/static/media/abeille-bg.46b91e37.jpg) no-repeat center;
  background-size: cover;
}

.logo-footer {
  width: 30%;
}

.form-control {
  width: 60%;
  display: unset;
  height: 40px;
}

.form-newsletter {
  margin-top: 3%;
}

.newsletter-block {
  display: -webkit-inline-flex;
  display: inline-flex;
}
.newsletter-form {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 10px;
  padding-right: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-radius: 4rem;
  color: darkgray;
  width: 300px;
  height: 40px;
  border: solid 2px white;
}

.newsletter-icon {
  position: relative;
  left: -27px;
  top: 4px;
  pointer-events: none;
  z-index: 5;
}

.btn-round {
  outline: none !important;
  box-shadow: none !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: #a3c6b1;
  border-radius: 50%;
  border: solid 2px white;
  color: white;
  width: 40px;
  height: 40px;
  z-index: 3;
  margin-left: -12%;
}

.copyright {
  padding: 20px 20px 20px 20px;
  background: #1a1a1a;
  z-index: 3;
}

/* faq */

.row-title-text {
  font-weight: bold;
}

@media (max-width: 1199px) {
  .sm-hidden {
    visibility: hidden;
    height: 0vh;
  }

  .m-block {
    display: block !important;
  }

  .logo {
    width: 30%;
  }
  /* font size */
  .sm-font-12px {
    font-size: 12px !important;
  }
  .sm-font-14px {
    font-size: 14px !important;
  }
  .sm-font-16px {
    font-size: 16px !important;
  }
  .sm-font-18px {
    font-size: 18px !important;
  }
  .sm-font-20px {
    font-size: 20px !important;
  }
  .sm-font-26px {
    font-size: 26px !important;
  }
  .sm-font-30px {
    font-size: 30px !important;
  }
  .sm-font-35px {
    font-size: 35px !important;
  }
  .sm-font-40px {
    font-size: 40px !important;
  }
  .sm-font-50px {
    font-size: 50px !important;
  }
  .sm-font-60px {
    font-size: 60px !important;
  }
  .sm-font-70px {
    font-size: 70px !important;
  }
  .sm-font-80px {
    font-size: 80px !important;
  }
  .sm-font-90px {
    font-size: 90px !important;
  }
  .sm-font-100px {
    font-size: 100px !important;
  }
  .sm-font-120px {
    font-size: 120px !important;
  }
  .sm-font-130px {
    font-size: 130px !important;
  }

  /* bg */
  .fp-section {
    background-color: rgba(255, 255, 255, 0.541) !important;
  }

  .fp-table {
    height: auto !important;
  }
  .loc-img1,
  .loc-img2,
  .loc-img3,
  .loc-img4,
  .img1,
  .img2,
  .img3,
  .img4 {
    width: 107%;
    height: 300px;
  }

  /* margin */
  .sm-mt--10 {
    margin-top: -48% !important;
  }

  .sm-mt-10 {
    margin-top: 10%;
  }

  .sm-mb-10 {
    margin-bottom: 10%;
  }

  .sm-plr-10 {
    padding-right: 10%;
    padding-left: 10%;
  }

  .sm-mlr-5 {
    margin-right: 5%;
    margin-left: 5%;
  }

  .sm-mt-5 {
    margin-top: 5%;
  }

  .sm-text-center {
    text-align: center;
  }

  /* carrousel */

  .carousel-cueilleur .swiper-container {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    height: 60vh;
  }
  .carousel-cueilleur .swiper-slide {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .carousel-cueilleur .swiper-slide img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    display: block;
    object-fit: cover;
  }

  .videoTag {
    height: 100vh;
    top: 0;
    width: 300vw;
    left: -150%;
    position: fixed; /* optional depending on what you want to do in your app */
  }
}

/* base */

.nav {
  display: block;
}

#header {
  padding: 2rem;
  /* background: red;

  height: 7em; */
}

#menu {
  background: #e2e9f1;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  transition: all 0.7s ease-in-out;
  width: 100%;
  z-index: -99;
}

/*  menu ouvert */

#menu.open {
  opacity: 0.95;
  visibility: visible;
  z-index: 99;
}
#menu.open li:nth-child(1) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
#menu.open li:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
#menu.open li:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
#menu.open li:nth-child(4) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
#menu.open li:nth-child(5) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
#menu.open li:nth-child(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
#menu.open li:nth-child(7) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
#menu.open li:nth-child(8) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
#menu.open li:nth-child(9) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
#menu.open li:nth-child(10) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
#menu.open li {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}
/* lien */
#menu ul:hover a {
  opacity: 0.5;
}
#menu ul a {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: opacity 0.3s ease-in-out;
}
#menu ul a:hover {
  opacity: 1;
}

/* icones */
#menu .main-nav {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#menu .main-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#menu .main-nav a {
  color: #82293b;
  display: block;
  font-size: 19px;
  font-weight: bold;
  padding: 1rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
}
#menu .main-nav a:first {
  padding-top: 0;
}

/* footer menu  */
#menu .menu-footer {
  padding: 2rem;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
#menu .menu-footer ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
#menu .menu-footer ul li {
  display: inline;
  margin: 0 1rem;
}
#menu .menu-footer a {
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 2vh;
}

/* boutons toggle */
.toggle-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 50px;
  outline: none;
  padding: 0;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  width: 50px;
  z-index: 1110;
}
.toggle-menu span {
  background-color: #82293b;
  content: "";
  display: block;
  height: 3px;
  left: calc(50% - 13px);
  position: absolute;
  top: calc(50% - 1px);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    -webkit-transform 0.2s linear;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear, -webkit-transform 0.2s linear;
  width: 26px;
}
.toggle-menu span:before,
.toggle-menu span:after {
  background-color: #82293b;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    -webkit-transform 0.2s linear;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear, -webkit-transform 0.2s linear;
}
.toggle-menu span:before {
  top: 7px;
  width: 13px;
}
.toggle-menu span:after {
  top: -7px;
  width: 13px;
  right: 0px;
}

/* bouton toggle ouvert */

#button.open span {
  background-color: transparent;
  transition: background 0.2s ease-out;
}
#button.open span:before {
  transition: top 0.2s ease-out;
}
#button.open span:after {
  -webkit-transform: 0.2s 0.2 ease-out;
          transform: 0.2s 0.2 ease-out;
}
#button.open span:before {
  top: 0;
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
          transform: rotate3d(0, 0, 1, -45deg);
  width: 26px;
}
#button.open span:after {
  top: 0;
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
          transform: rotate3d(0, 0, 1, 45deg);
  width: 26px;
}

/* icones */
#insta {
  width: 2vh;
  height: 2vh;
  color: #82293b;
  margin-right: 3px;
}

#Facebook {
  color: #82293b;
  width: 2vh;
  height: 2vh;
}
#youtube {
  color: #82293b;
  width: 2vh;
  height: 2vh;
  margin-right: 3px;
}

#Linkedin {
  color: #82293b;
  width: 2vh;
  height: 2vh;
  margin-right: 3px;
}

.shop {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 50px;
  outline: none;
  padding: 0;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  width: 50px;
  z-index: 2;
}
#shop {
  color: #82293b;
  width: 3vh;
  height: 3vh;
}

#compte {
  color: #82293b;
  width: 3vh;
  height: 3vh;
}

/* ANIMATION */

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/* @media (max-width: 1000px) {
  .toggle-menu {
    width: 150px;
    height: 70px;
  }
  .toggle-menu span {
    height: 9px;
    width: 60px;
  }
  .toggle-menu span:before,
  .toggle-menu span:after {
    height: 9px;
  }
  .toggle-menu span:before {
    top: 20px;
    width: 20px;
  }
  .toggle-menu span:after {
    top: -20px;
    width: 20px;
  }

  #shop {
    width: 3vh;
    height: 3vh;
  }
} */

