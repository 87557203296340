@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");

body {
  font-family: "Montserrat";
  min-height: -webkit-fill-available;
}

#fullpage-wrapper {
  margin-top: -65px;
}

.logo {
  width: 10%;
  z-index: 99999;
  position: absolute;
}

/* fond */
.fondPanier {
  background: url(../img/background/shop.png) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 30vh;
  margin-top: -9%;
}

.fond {
  /* background: url(../img/background/fleur2.jpg) no-repeat center; */
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}

.videoTag {
  width: 100vw;

  top: 0;
  padding: none;
  position: fixed; /* optional depending on what you want to do in your app */
}

.loc-img1 {
  background: url(../img/ruche/biodiversite.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
  margin-left: -3%;
}

.loc-img2 {
  background: url(../img/location/entreprise2.jpg) no-repeat center;
  background-size: cover;
  height: 100vh;
  width: 51vw;
}

.loc-img3 {
  background: url(../img/location/particulier.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
  margin-left: -3%;
}
.loc-img4 {
  background: url(../img/location/particulier2.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
}
.img1 {
  background: url(../img/ruche/biodiversite2.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
  margin-left: -3%;
}
.img2 {
  background: url(../img/ruche/famille.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
}

.img3 {
  background: url(../img/ruche/rucheenhauteur.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
  margin-left: -3%;
}

.img4 {
  background: url(../img/ruche/rucheconnectee.jpg) no-repeat center;
  background-size: cover;
  width: 51vw;
  height: 100vh;
}
.fondEq {
  background: url(../img/background/fondEquipe.jpeg) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.fondLocation {
  background: url(../img/background/shop.jpg) no-repeat center;
  background-size: cover;
  width: 100%;
  margin-top: 5%;
  height: 30vh;
}

.fondM {
  background: url(../img/background/feuille.jpg) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  margin-top: -65px;
  z-index: 1;
}

.fondShop {
  background: url(../img/background/shop.jpg) no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 20vh;
  margin-top: -5%;
}

/* section */
.fp-section {
  background-color: transparent;
}

/* text-color */
.text-red {
  color: #82293b;
}
.text-red-link {
  color: #82293b;
  text-decoration: underline;
}

.text-red-link:hover {
  color: #82293b;
}

.text-blue {
  color: #1e362d;
}

.text-blue-link {
  color: #1e362d;
}

.text-blue-link:hover {
  color: #1e362d;
}

.text-light-blue {
  color: #2e6766;
}
.text-light-blue-link {
  color: #2e6766;
}
.text-light-blue-link:hover {
  color: #2e6766;
}

.text-white {
  color: #fff;
}

.lien {
  text-decoration: none !important;
  color: #000 !important;
}
/* texte-size */
.font-12px {
  font-size: 12px;
}
.font-14px {
  font-size: 14px;
}
.font-16px {
  font-size: 16px !important;
}
.font-18px {
  font-size: 18px;
}
.font-20px {
  font-size: 20px !important;
}
.font-26px {
  font-size: 26px;
}
.font-30px {
  font-size: 30px;
}
.font-35px {
  font-size: 35px;
}
.font-40px {
  font-size: 40px;
}
.font-50px {
  font-size: 50px;
}
.font-60px {
  font-size: 60px !important;
}
.font-70px {
  font-size: 70px;
}
.font-80px {
  font-size: 80px;
}
.font-90px {
  font-size: 90px;
}
.font-100px {
  font-size: 100px !important;
}
.font-120px {
  font-size: 120px;
}
.font-130px {
  font-size: 130px;
}

.font-italic {
  font-style: italic;
}
.font-bold {
  font-weight: bold;
}
.font-normal {
  font-style: normal;
}

/* margin */

.mt-10 {
  margin-top: 10%;
}

.mb-10 {
  margin-bottom: 10%;
}

.plr-5 {
  padding-right: 5%;
  padding-left: 5%;
}

/* btn */
.swiper-pagination-bullet-active {
  background-color: #2e6766;
}

.swiper-button-next,
.swiper-button-prev {
  color: #2e6766;
}

.btn-dark {
  background-color: #82293b;
  padding: 3%;
  color: white !important;
  border: none;
}

.btn-location {
  background-color: white;
  color: #82293b;
  padding: 5%;
  border-color: #82293b;
  width: 100%;
}

.btn-qt {
  background-color: #82293b;
  color: white !important;
  text-decoration: none !important;
  margin: 10px;
  padding: 10px;
}

.btn-qt::hover {
  background-color: #310d14 !important;
  text-decoration: none !important;
  color: white !important;
}

.btn-location:hover {
  background-color: #82293b;
  border-color: #82293b;
}

/* bouton ajouter au panier -------------------------- */

.cart-button {
  position: relative;
  padding: 10px;
  width: 300px;
  height: 60px;
  border: 0;
  background-color: #82293b;
  border-radius: 4rem;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  overflow: hidden;
}
.cart-button:hover {
  background-color: #44111b;
}
.cart-button:active {
  transform: scale(0.9);
}

.cart-button .fa-shopping-cart {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: -10%;
  font-size: 2em;
  transform: translate(-50%, -50%);
}
.cart-button .fa-box {
  position: absolute;
  z-index: 3;
  top: -20%;
  left: 52%;
  font-size: 1.2em;
  transform: translate(-50%, -50%);
}
.cart-button span {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  font-size: 1em;
  color: #fff;
  transform: translate(-50%, -50%);
}
.cart-button span.add-to-cart {
  opacity: 1;
}
.cart-button span.added {
  opacity: 0;
}

.cart-button.clicked .fa-shopping-cart {
  animation: cart 1.5s ease-in-out forwards;
}
.cart-button.clicked .fa-box {
  animation: box 1.5s ease-in-out forwards;
}
.cart-button.clicked span.add-to-cart {
  animation: txt1 1.5s ease-in-out forwards;
}
.cart-button.clicked span.added {
  animation: txt2 1.5s ease-in-out forwards;
}
@keyframes cart {
  0% {
    left: -10%;
  }
  40%,
  60% {
    left: 50%;
  }
  100% {
    left: 110%;
  }
}
@keyframes box {
  0%,
  40% {
    top: -20%;
  }
  60% {
    top: 40%;
    left: 52%;
  }
  100% {
    top: 40%;
    left: 112%;
  }
}
@keyframes txt1 {
  0% {
    opacity: 1;
  }
  20%,
  100% {
    opacity: 0;
  }
}
@keyframes txt2 {
  0%,
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* card */

.cardShop {
  border-radius: 50px;
  box-shadow: 0px 5px 5px 0px rgba(122, 122, 122, 0.4);
  background-color: #fff;
  padding: 10%;
  transition: transform 250ms;
}

.cardShop:hover {
  box-shadow: 0px 10px 10px 0px rgba(122, 122, 122, 0.4);
  transform: scale(1.1);
  cursor: pointer;
}

.cardShop h1,
.cardShop h2,
.cardShop h3 {
  color: #82293b;
  text-transform: uppercase;
  text-align: center;
}

.cardImg {
  width: 100%;
  height: 35%;
  margin: 5%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.prix {
  text-align: center;
  color: #82293b;
  font-weight: bold;
}

.card {
  border-radius: 50px;
  box-shadow: 0px 5px 5px 0px rgba(122, 122, 122, 0.4);
  background-color: #fff;
  padding: 3%;
  margin-left: 4%;
}

/* form */

.cp-form {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 30px;
  padding-right: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-radius: 4rem;
  background-color: rgb(236, 236, 236);
  color: grey;
  width: 300px;
  height: 40px;
  border: solid 2px white;
}

.form-contact {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 10px;
  padding-right: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-radius: 4rem;
  width: 100%;
  margin: 1%;
  height: 50px;
  border: none;
  background-color: rgb(230, 230, 230);
}

.form-contact-message {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-radius: 1.5rem;
  width: 100%;
  margin: 1%;
  height: 250px;
  border: none;
  background-color: rgb(230, 230, 230);
}

/* carrousel cueilleur */

.carousel-cueilleur .swiper-container {
  height: 85vh;
  overflow-y: hidden;
}

.carousel-cueilleur .swiper-slide {
  text-align: center;
  background: transparent;
  width: 30vw;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.carousel-cueilleur .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* img */

.hidden {
  visibility: hidden;
  height: 0px;
  margin: 0px;
}

.img-responsive {
  width: 100%;
}

.img-active {
  margin-bottom: 5%;
}

.img-produit:hover {
  cursor: pointer;
  opacity: 0.8;
}

.photo-equipe {
  height: 30vh;
  width: 70%;
}

.photo-equipe img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-chrono {
  width: 90%;
}

/* footer */

.footer {
  background-color: #383838;
  color: white;
  font-size: 1em;
  padding: 10px 10px 10px 10px;
  border-top: 5px solid #a3c6b1;
  background: #383838 url(../img/background/abeille-bg.jpg) no-repeat center;
  background-size: cover;
}

.logo-footer {
  width: 30%;
}

.form-control {
  width: 60%;
  display: unset;
  height: 40px;
}

.form-newsletter {
  margin-top: 3%;
}

.newsletter-block {
  display: inline-flex;
}
.newsletter-form {
  outline: none !important;
  box-shadow: none !important;
  padding-left: 10px;
  padding-right: 35px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-radius: 4rem;
  color: darkgray;
  width: 300px;
  height: 40px;
  border: solid 2px white;
}

.newsletter-icon {
  position: relative;
  left: -27px;
  top: 4px;
  pointer-events: none;
  z-index: 5;
}

.btn-round {
  outline: none !important;
  box-shadow: none !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: #a3c6b1;
  border-radius: 50%;
  border: solid 2px white;
  color: white;
  width: 40px;
  height: 40px;
  z-index: 3;
  margin-left: -12%;
}

.copyright {
  padding: 20px 20px 20px 20px;
  background: #1a1a1a;
  z-index: 3;
}

/* faq */

.row-title-text {
  font-weight: bold;
}
